

:root {
    --side-width: 250px;
    --side-width-shrinked: 50px;


    --link-padding:15px 10px 15px 0;

    --app-inner-width: 1000px;


    /* App Colours */
    --app-background: #f8f8f8;  /* #f8f8f8 */
    --app-midground: #FFF;
    --app-component: #FFF;
    --app-text: #000;       /* #000 */
    --hover-bg-colour:#EEE; /* #EEE */
}

* {
    transition:0.13s all cubic-bezier(.04,.53,.38,.97);
}

h1 {
    color:var(--app-text);
}

.app-container {
    background:var(--app-background);

    width:100%;
    height:100vh;
    display:flex;

    /* When main transforms right when panel opens */
    overflow-x:hidden; 
}

.side {
    overflow:hidden;
    position:fixed;
    height:100vh;
    z-index:9000;
}

.side.true,
.side.false.hovertrue {
    width:var(--side-width);
}
.side.true {
    background:var(--app-midground);
}

.side.false {
    width:var(--side-width-shrinked);

    background: hsla(0,0%,100%,.425);
    backdrop-filter: blur(20px);
}

.side.false {
    opacity:0.5;
} .side.false.hovertrue {
    opacity:1;
}

.side.presentingtrue {
    background:var(--colour-blue-light);
}

.side.presentingtrue .tab .tab-text,
.side.presentingtrue .utility .utility-text {
    color:var(--colour-blue);
}

/* presenting mode */
.side.presentingtrue .tab:hover,
.side.presentingtrue .tab .tab-text.active,
.side.presentingtrue .utility .utility-text:hover {
    background:var(--colour-blue);
    color:white;
}

.side.presentingtrue .tab .tab-text:hover {
    color:white;
}

.side-inner {
    width:var(--side-width);
    height:100%;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
}

.side img,
.side svg {
    width:var(--side-width-shrinked);
    box-sizing: border-box;
    padding:0 15px;
    user-select:none;
}

.side .whitespace {
    flex-grow:1;
}

.side .tab {
    margin:10px 0;
    cursor:pointer;
}

.side .tab:hover {
    background:var(--hover-bg-colour);
}

.side .tab .tab-text {
    user-select:none; 
    padding:var(--link-padding);
    color:var(--app-text);
}

.side .tab .tab-text.active {
    background:var(--hover-bg-colour);
}

.side .tab .tab-text,
.side .utility .utility-text {
    display:flex;
    align-items:center;
}


.side .utility {
    justify-self:flex-end;
}

.side .utility .utility-text {
    margin:10px 0;
    cursor:pointer;
    padding:var(--link-padding);
    color:var(--app-text);
    text-decoration:none;
}

.side .utility .utility-text:hover {
    background:var(--hover-bg-colour);
}

.side .utility .utility-text.external {
    display:flex;
    justify-content:space-between;
}

.side .utility .utility-text.external .internetspace {
    flex-grow:1;
}

.side .utility-text img.mini-icon {
    height:15px;
    width:15px;
    padding:0;
    margin-right:10px;
}

.side.true .utility-text svg.arrow {
    transform:none;
}

.side.false .utility-text svg.arrow {
    transform:rotate(180deg);
}





.app-container.slidtrue .app-main {
    width:calc(100% - var(--side-width-shrinked));
    transform:translate(var(--side-width));
}

.app-container.slidfalse .app-main {
    width:calc(100% - var(--side-width-shrinked));
    transform:translate(var(--side-width-shrinked));
}





.app-main .centre-stamp {
    width:100%;
    height:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    color:#CCC;
    user-select:none;
}




.app-inner {
    width:100%;
    display:flex;
    
    box-sizing: border-box;
    padding:5vw;
}


.app-inner .app-inner-inner {
    width:var(--app-inner-width);
    margin: 0 auto;
}

.app-inner .rollcall .country {
    display:flex;   
}

.rollcall .clc {
    margin:40px 0 0 0;
    border-radius:4px;
    overflow:hidden;
}

.rollcall .clc .country {
    background:var(--app-component);
    display:flex;
    justify-content:flex-end;
    font-weight:bold;
}

.rollcall .clc .country.present {
    background:var(--colour-yellow-light);
}

.rollcall .clc .country.voting {
    background:var(--colour-green-light);
}

.rollcall .clc .internetspace {
    flex-grow:1;
}

.rollcall .clc .country-name {
    padding:20px;
    align-self:flex-start;
    color:var(--app-text);
}

.rollcall .clc .country-present,
.rollcall .clc .country-voting {
    padding:20px;
    cursor:pointer;
    user-select: none;
}

.rollcall .clc .country-present:hover {
    border-radius:4px;
    color:var(--colour-yellow);
}

.rollcall .clc .country-voting:hover {
    border-radius:4px;
    color:var(--colour-green);
}

.rollcall .clc .country-present.present:hover,
.rollcall .clc .country-voting.voting:hover {
    color:var(--colour-red);
}

.rollcall .clc .country:last-child {
    border:none;
}



.one-liner {
    display:flex;
    justify-content: space-between;
    align-items: center;
}

.motions .add-motion {
    padding:10px 15px;
    border-radius:4px;
    display:table;
    cursor:pointer;
    font-weight: bold;
    user-select:none;
    background:var(--colour-green-light);
    color:var(--colour-green);
}

.motions .add-motion:hover {
    background:var(--colour-green);
    color:white;
}

.motions .add-motion.disabled,
.motions .add-motion.disabled:hover {
    background:black;
    color:#777;
    cursor:default;
}

.notification {
    margin:50px 0 40px 0;
    background:var(--colour-orange-light);
    border-style: none none none solid;
    border-width:5px;
    border-color:var(--colour-orange);
    border-radius:4px;
    box-shadow: 0 0 10px rgba(0,0,0,0.01);
    padding:20px;
}

.notification h3 {
    padding:10px 0;
    font-size:1.5rem;
}

.notification p {
    line-height:30px;
}

.notification .action {
    display:flex;
    padding-top:10px;
}

.notification .action .noti-text-link {
    margin-right:20px;
    color:var(--colour-orange);
    font-weight:bold;
    cursor:pointer;
}

.notification .action .noti-text-link:hover {
    color:var(--colour-orange-dark);
}

.motions-grid {
    display:flex;
    flex-direction:column;
    margin-top:35px;
}

.motions-grid .motion-item {
    margin-top:35px;
    
    height:auto;
    animation-name: appear;
    animation-timing-function: cubic-bezier(.04,.53,.38,.97);
    animation-duration: 0.2s;
}
.motions-grid .motion-item.hide,
.notification.hide {
    animation-name: disappear;
    animation-duration: 0.2s;
}

@keyframes appear {
  from {
      height:0;
      opacity:0;
      transform:translateY(-10%);
  }
  to {
      opacity:1;
      transform:translateY(0);
  }
}
@keyframes disappear {
  from {
      opacity:1;
  }
  to {
    height:0;
    margin-top:-1px;
    opacity:0;
  }
}

.motions-grid .motion-item .motion-preview {
    padding:20px;
    background:white;
    font-size:23px;
    font-weight:bold;
    width:100%;
    box-sizing: border-box;
}

.motions-grid .motion-item .motion-edit {
    box-shadow:0 0 10px rgba(0,0,0,0.01);
    border-radius:4px;
    overflow:hidden;
    display:flex;
    width:100%;
}

.motions-grid .motion-item .inputs {
    display:flex;
    box-sizing: border-box;
    padding:20px;
    
    background: hsla(0,0%,100%,.425);
    backdrop-filter: blur(20px);
    box-shadow: 0 0 10px rgb(0 0 0 / 1%);
    flex-grow:1;
}

.motions-grid .motion-item .motion-action {
    background:var(--colour-blue-light);
    color:var(--colour-blue);
    cursor:pointer;
    font-weight:bold;
    width:85px;
    justify-content: center;
    padding:15px;
    display:flex;
    align-items:center;
    user-select: none;
}

.motions-grid .motion-item .motion-action:hover {
    background:var(--colour-blue);
    color:white;
}

.motions-grid .motion-item .input-group {
    margin:0;
    display:flex;
    flex-direction:column;
    margin-right:20px;
}

.motions-grid .motion-item .input-group input {
    width:100%;
}

.motions-grid .motion-item .motion-edit .time-input {
    display:flex;
    width:300px;
}

.motions-grid .motion-item .motion-edit .time-input .input-group {
    width:100%;
}

.motions-grid .motion-item .input-group.motion {
    flex-grow:1;
    margin-right:0;
}

.motions-grid .motion-item .input-group select {
    padding:9px 15px;
    border-radius:4px;
    border:none;
    background:#eee;
    outline:none;
    cursor:pointer;
    font-size:16px;
    margin-top:10px;
    max-width: 220px;
}

.motions-grid .motion-item {
    display:flex;
    flex-direction: column;
    align-items:flex-end;
}

.motions-grid .motion-item .subtle-delete {
    font-weight:bold;
    color:#CCC;
    cursor:pointer;
    margin-top:10px;
}

.motions-grid .motion-item .subtle-delete:hover {
    color:#777;
}





.no-caucus {
    margin-top:35px;
}

.caucus .information {
  margin:35px 0;
  display:flex;
  flex-direction:column;
}

.caucus .information-text {
  padding: 20px;
  box-sizing: border-box;
  border-radius:4px 4px 0 0;
  background:white;
}

.caucus h3 {
  color:#777;
  padding-bottom:15px;
}

.caucus .information-text h2 {
  font-size:2rem;
}

.caucus .information-timer {
  height:10px;
  background:#CCC;
  width:100%;
  display:table;
  box-shadow: 0 0 10px rgba(0,0,0,0.01);
  border-radius:0 0 4px 4px;
  overflow:hidden;
}

.elapsed {
  height:10px;
  background:var(--colour-blue);
  transition:1s width linear; /* to make the timer linear hehe */
}

.elapsed.expired {
    background:var(--colour-red);
}

.timer-band {
    background:#ccc;
    margin: 20px 0;
    border-radius:4px;
    overflow:hidden;
}

.speaker  {
    display:flex;
    justify-content:center;
    margin:100px 0;
}

.speaker-inner {
    text-align:center;
    width:100%;
}

.speaker-inner .timer {
    width:100%; 
}

.speaker-inner .country {
    padding-bottom:20px;
    padding-top:30px;
    border-radius:4px 4px 0 0;
    background:white;
    box-shadow: 0 0 10px rgba(0,0,0,0.01);
}

.speaker-inner .country .img {
    max-width:500px;
}

.speaker-inner .timer-band {
    margin:0;
    border-radius:0;
}

.speaker-inner .country-actions {
    padding:20px 0;
    background: hsla(0,0%,100%,.425);
    backdrop-filter: blur(20px);
    border-radius: 0 0 4px 4px;
    box-shadow: 0 0 10px rgba(0,0,0,0.01);
}

.options {
    display:flex;
    padding-top:20px;
    align-items:center;
    justify-content:center;
}

.options .media-button {
    margin-right:15px;
    padding:10px 15px;
    border-radius:4px;
    background:#eee;
    cursor:pointer;
    user-select: none;
}

.options .media-button:hover {
    background:#ccc;
}

.options .media-button:last-child {
    margin-right:0;
}


.caucus .caucus-action-tray {
  margin-top:50px;
  display:flex;
  justify-content:space-between;
}

.caucus .equalizaaa,
.caucus .manage-speaker {
  width:49%;
}

.caucus .manage-speaker,
.caucus .manage-caucus {
  padding:20px;
  border-radius:4px;
  background:white;
  box-sizing: border-box;
  box-shadow: 0 0 10px rgba(0,0,0,0.01);
}

.caucus .manage-caucus {
  width:100%;
}

.caucus .speaker-container {
  display:flex;
  flex-direction:column;
  box-sizing: border-box;
}

.caucus .speaker-search,
.speakers .speaker-search {
    display:flex;
    align-items:center;
    margin-bottom:15px;
}

.caucus .speaker-search img,
.speakers .speaker-search img {
    position:relative;
    margin-right:-30px;
    padding-left:10px;
    height:20px;
    width:20px;
}

.caucus .speaker-search input[type="text"],
.speakers .speaker-search input[type="text"] {
  padding:10px 10px 10px 40px;
  outline:none;
  border:none;
  width:100%;
  box-sizing: border-box;
  font-size:1rem;
  background:#f3f3f3;
  border-radius:4px;
}

.caucus .speaker-search input[type="text"]:hover,
.caucus .speaker-search input[type="text"]:focus {
    background:#eee;
}

.caucus .manage-speaker .speaker-list {
  max-height:400px;
  overflow:auto;
  background:#eee;
  border-radius:4px;
}

.caucus .manage-speaker .speaker-list .speaker-item {
  padding:10px;
  border-style:none none solid none;
  border-width:1px;
  border-color:#ccc;
  cursor:pointer;
  user-select:none;
}

.caucus .manage-speaker .speaker-list .speaker-item.active {
    background:var(--colour-blue-light);
    cursor:default;
}

.caucus .manage-speaker .speaker-list .speaker-item:hover {
  background:#ccc;
}

.caucus .manage-speaker .speaker-list .speaker-item.active:hover {
    background:var(--colour-blue-light);
}

.caucus .manage-speaker .speaker-list .speaker-item:last-child {
  border:none;
  border-radius: 0 0 4px 4px;
}

.no-speaker {
    color:#CCC;
    font-weight:500;
    padding:50px 0;
}

.caucus .unmod-time {
    margin-bottom:50px;
    padding-top:20px;
    box-sizing: border-box;
    display:flex;
    flex-direction:column;
    align-items:center;
}

.caucus .manage-caucus .delete-caucus {
  padding:10px 15px;
  font-weight:bold;
  border-radius:4px;
  cursor:pointer;
  background:var(--colour-orange-light);
  color:var(--colour-orange);
  display:table;
  user-select: none;
}

.caucus .manage-caucus .delete-caucus:hover {
  background:var(--colour-orange);
  color:white;
}






.app-inner .program-options {
    margin-top:40px;
}

.app-inner .program-options .option-block {
    padding-bottom:40px;
    display:flex;
    flex-direction:column;
    align-items:flex-start;
}

.app-inner .program-options .option-block .text-field {
    display:flex;
    padding-top:15px;
    align-items: center;
}

.option-block .text-field input[type='text'] {
    padding:10px;
    outline:none;
    border:none;
    border-radius:4px;
    font-size:16px;
    width:30px;
    margin-right:10px;
    text-align: center;
}


.option-block .option-save {
    padding:10px 15px;
    font-weight:bold;
    background:var(--colour-green-light);
    user-select:none;
    border-radius:4px;
    box-shadow: 0 0 10px rgba(0,0,0,0.01);
    display:table;
    cursor:pointer;
    color:var(--colour-green);
}

.option-block .option-save:hover {
    color:white;
    background:var(--colour-green);
}

.option-block select {
    padding:10px 15px;
    outline:none;
    border:none;
    border-radius:4px;
    font-size:16px;
    margin-top:15px;
    cursor:pointer;
    width:250px;
}

.toggle-container {
    display:flex;
    padding-top:20px;
    align-items:center;
    width:auto;
}

.toggle-container .toggle-value {
    padding-left:15px;
}

.custom-toggle {
    cursor:pointer;
    width:50px;
    border-radius:20px;
    background:var(--colour-orange);
    box-sizing: border-box;
    cursor:pointer;
}

.custom-toggle .toggle-slider {
    padding:5px;
    width:100%;
    box-sizing: border-box;
    display:flex;
}

.custom-toggle .toggle-dot {
    height:20px;
    width:20px;
    background:white;
    border-radius:100%;
}

/* right = active/yes/affirmative/neh */
.custom-toggle.right .toggle-slider {
    padding-left:25px;
}

.custom-toggle.right {
    background:var(--colour-blue);
}

.custom-toggle.dark.right {
    background:#161616;
}



/* Speakers page style */

.speakers .speaker-lists {
    display:flex;
}

.speaker-lists .speaker-type {
    margin-left:10px;
    padding:10px 15px;
    font-weight:bold;
    background:#EEE;
    cursor:pointer;
    user-select:none;
    border-radius:4px;
}

.speaker-lists .speaker-type.active {
    background:var(--colour-blue);
    color:white;
}

.speakers-inner {
    padding-top:35px;
}

.speakers-inner h2 {
    color:#777;
}


.speakers-bot {
    display:flex;
    justify-content: space-between;
}

.speakers-bot .pants {
    width:31%;
    box-sizing: border-box;
}

.speakers-add,
.speakers-list,
.speakers-manage {
    box-sizing: border-box;
    padding:20px;
    background:white;
    width:100%;
    border-radius:4px;
}

.speakers-container {
    padding:50px 0;
}

.speakers-add.disabled {
    user-select: none;
    opacity:0.420;
}

.speakers .country-in-list {
    padding:10px;
    background:#EEE;
    border-style:none none solid none;
    border-width:1px;
    border-color:#CCC;
    cursor:pointer;
}

.speakers .country-in-list:first-child {
    border-radius:4px 4px 0 0;
}
.speakers .country-in-list:last-child {
    border:none;
    border-radius:0 0 4px 4px;
}

.country-in-list.selected {
    background:var(--colour-blue-light);
}

.speakers-bot.speaking .country-in-list.selected {
    background:none;
}

.speakers .remove,
.speakers .start,
.speakers .button {
    padding:10px 15px;
    margin-top:15px;
    display:table;
    border-radius:4px;
    cursor:pointer;
    font-weight:500;
}

.speakers .remove {
    background:var(--colour-red-light);
    color:var(--colour-red);
}

.speakers .remove:hover {
    background:var(--colour-red);
    color:white;
}

.speakers .start {
    background:var(--colour-green-light);
    color:var(--colour-green);
}

.speakers .start:hover {
    background:var(--colour-green);
    color:white;
}

.speakers .button {
    background:var(--colour-orange-light);
    color:var(--colour-orange);
}

.speakers .button:hover {
    background:var(--colour-orange);
    color:white;
}

.speakers .speaker-list {
    border-radius:4px;
    max-height:500px;
}

.speakers-bot h3,
.country-picker h3 {
    padding-bottom:15px;
}

.speakers .speaker-list {
    overflow:auto;
}

.speakers .country-to-add {
    padding:10px;
    background:#EEE;
    border-style:none none solid none;
    border-width:1px;
    border-color:#CCC;
    cursor:pointer;
}

.speakers .country-to-add:first-child {
    border-radius:4px 4px 0 0;
}

.speakers .country-to-add:last-child {
    border-radius:0 0 4px 4px;
    border-style:none;
}

.speakers .country-to-add:hover {
    background:rgb(228, 228, 228);
}

.speakers .country-to-add.active {
    cursor:default;
    background:var(--colour-blue-light);
}

.speakers .country-to-add.added {
    background:rgb(199, 199, 199);
    cursor:default;
}

.speakers .nothing {
    color:#CCC;
}

.speakers .speaker {
    margin:50px 0 0 0;
    padding:30px 0 20px 0;
    background:white;
    border-radius:4px 4px 0 0;
    display:flex;
    flex-direction:column;
    align-items:center;
}

.speakers .speaker h2 {
    color:black;
}

.speaker-action,
.speaker-timer {
    background: hsla(0,0%,100%,.425);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    border-radius: 0 0 4px 4px;
    box-shadow: 0 0 10px rgb(0 0 0 / 1%);
}

.speaker-timer {
    text-align:center;
}

.speaker-timer .elapsed {
    margin-bottom:20px;
}

.speaker-timer .timer-container {
    background:#CCC;
}

.speaker-action {
    display:flex;
    justify-content: center;
    padding: 10px 0 20px 0;
}

.speaker-action .button {
    background:#EEE;
    color:black;
    margin-right:15px;
}

.speaker-action .button:last-child {
    margin-right:0;
}

.speaker-action .button:hover {
    background:#CCC;
    color:black;
}

.speakers .inactive {
    padding:100px 0;
    text-align:center;
    color:#CCC;
    font-weight:500;
}





/* Presenter View */

.present-wrapper {
    margin:0 auto;
    width:85%;
    padding:100px 0;
    
    height:100vh;
    overflow:hidden;
}

.present-wrapper h2 {
    padding-bottom:30px;
}

.present-country {
    display:flex;
    width:100%;
    justify-content:space-between;
    padding:10px 0;
    font-size:1.2rem;
}

.present-country-name {
    font-weight:bold;
}

.present-country-presence {
    text-align:right;
    font-weight:bold;
}

.present-country-presence.absent {
    color:var(--colour-red);
}

.present-country-presence.voting,
.present-country-presence.present {
    color:var(--colour-green-dark);
}








/* Darkmode Override Styles */

.app-container.dmtrue {
    background:#1E1E1E;
    color:#777;
}

.app-container.dmtrue .no-speaker {
    color:#777;
}

.app-container.dmtrue h1 {
    color:#777;
}

.app-container.dmtrue img {
    user-select:none;
    stroke:white;
}

.app-container.dmtrue .tab-text,
.app-container.dmtrue .utility-text {
    color:#777;
}

.app-container.dmtrue .side {
    background:#252526;
}

.app-container.dmtrue .side .utility .utility-text:hover,
.app-container.dmtrue .side .tab:hover,
.app-container.dmtrue .side .tab .tab-text.active {
    background:#37373D;
}

.app-container.dmtrue .country {
    background:#252526;
    color:#777;
}

.app-container.dmtrue .country .country-name {
    color:#CCC;
}

.app-container.dmtrue .information-text,
.app-container.dmtrue .manage-caucus,
.app-container.dmtrue .manage-speaker,
.app-container.dmtrue .country-actions {
    background:#252526;
}

.app-container.dmtrue .caucus input,
.app-container.dmtrue .motion-edit .inputs input,
.app-container.dmtrue .motion-edit .inputs select,
.app-container.dmtrue .option-block select,
.app-container.dmtrue .option-block .text-field input[type='text'],
.app-container.dmtrue .speakers input {
    background:rgba(0, 0, 0, 0.25);
    color:#777;
}
.app-container.dmtrue select option {
    background:#161616;
}

.app-container.dmtrue .caucus input:hover,
.app-container.dmtrue .caucus input:focus,
.app-container.dmtrue .motion-edit .inputs input:hover,
.app-container.dmtrue .motion-edit .inputs input:focus,
.app-container.dmtrue .option-block .text-field input[type='text']:hover,
.app-container.dmtrue .option-block .text-field input[type='text']:focus,
.app-container.dmtrue .speakers input:hover,
.app-container.dmtrue .speakers input:focus {
    background:rgba(0, 0, 0, 0.4);
}

.app-container.dmtrue .speaker-list {
    background:none;
}

.app-container.dmtrue .speaker-list .speaker-item {
    color:#777;
    background:#1E1E1E;
    border-color:#252526;
}

.app-container.dmtrue .speaker-list::-webkit-scrollbar {
    background:#1E1E1E;
} .app-container.dmtrue .speaker-list::-webkit-scrollbar-thumb {
    background:rgba(0, 0, 0, 0.25);
}

.app-container.dmtrue .speaker-list .speaker-item.active,
.app-container.dmtrue .speaker-list .speaker-item:hover {
    background:var(--colour-blue-light);
}

.app-container.dmtrue .caucus .media-button {
    background:var(--colour-blue-light);
    color:var(--colour-blue);
    font-weight:bold;
}

.app-container.dmtrue .caucus .media-button:hover {
    background:var(--colour-blue);
    color:white;
}

.app-container.dmtrue .information-timer,
.app-container.dmtrue .timer-band,
.app-container.dmtrue .timer-container {
    background:rgba(0, 0, 0, 0.25);
}

.app-container.dmtrue .motion-edit,
.app-container.dmtrue .motion-edit .inputs {
    background:#252526;
}

.app-container.dmtrue .motion-item .motion-preview {
    background:rgba(0, 0, 0, 0.1);
}

.app-container.dmtrue .motions-grid .motion-item .subtle-delete {
    color:#777;
} .app-container.dmtrue .motions-grid .motion-item .subtle-delete:hover {
    color:rgb(85, 85, 85);
}

.app-container.dmtrue .speakers-list,
.app-container.dmtrue .speakers-add,
.app-container.dmtrue .speakers-manage {
    background:#252526;
}

.app-container.dmtrue .speakers .country-to-add.added {
    background:#252526;
}

.app-container.dmtrue .speakers .country-in-list {
    background:#1E1E1E;
    border-color:#252526;
}

.app-container.dmtrue .speakers .country-in-list.selected {
    background:var(--colour-blue-light);
}

.app-container.dmtrue .speakers .nothing,
.app-container.dmtrue .speakers .inactive {
    color:#777;
}

.app-container.dmtrue .speaker-lists .speaker-type {
    background:#1E1E1E;
    color:#777;
}

.app-container.dmtrue .speaker-lists .speaker-type:hover {
    background:#252526;
}

.app-container.dmtrue .speaker-lists .speaker-type.active {
    background:#252526;
}

.app-container.dmtrue .speakers .speaker,
.app-container.dmtrue .speakers .speaker-timer,
.app-container.dmtrue .speakers .speaker-action {
    background:#252526;
}

.app-container.dmtrue .speaker-action .button {
    background:var(--colour-blue-light);
    color:var(--colour-blue);
    font-weight:bold;
}

.app-container.dmtrue .speaker-action .button:hover {
    background:var(--colour-blue);
    color:white;
}

.app-container.dmtrue .speakers .speaker h2 {
    color:#777;
}